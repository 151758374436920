<template>
  <div class="container live">
    <div class="back box-body">
      <div class="btn" @click="back">返回</div>
    </div>
    <div class="box-body">
      <div class="header ">
        <div class="title-box">
          <p class="title">开奖直播</p>
          <p class="sub">Live broadcast</p>
        </div>
      </div>
      <router-view class="box-live"></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Live',
  methods: {
    back () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@scss/pc/variables';
.live {
  margin-top: 10px;
  border: thin $color-border solid;

  .box-body {
    padding-left: 20px;
    padding-right: 20px;
  }

  .back {
    padding-top: 25px;
    padding-bottom: 25px;
    border-bottom: thin $color-border solid;

    .btn {
      background-color: #fa3e3e;
      color: white;
      width: 68px;
      height: 24px;
      border-radius: 12px;
      text-align: center;
      line-height: 24px;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    border-bottom: thin $color-border solid;

    > .title-box {
      display: flex;
      align-items: center;
      height: 45px;

      > .title {
        font-size: 18px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.8);
      }

      > .sub {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.5);
        margin-left: 20px;
      }
    }
  }
}
.box-live {
  margin-top: 30px;
}
</style>
